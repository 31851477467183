import React from 'react'
import { CSVLink } from 'react-csv'
import { useAuth } from '@clerk/nextjs'
import { type Transactions } from '@/types'
import { getTransactionLogs } from '@/services'
import {
  ModalButton,
  ModalButtonRow,
  ModalContainer,
  ModalRow,
  ModalTitle,
} from '@/components'
import { TileDatePicker } from '@/components/Charting/Editor/components/TileDatePicker'
import DownloadIcon from '@mui/icons-material/Download'
import { format, isDate } from 'date-fns'
import { Button, styled } from '@mui/material'
import { formatDateOnly } from '@/utility'

const StyledButton = styled(Button)({
  height: '32px',
  width: 'fit-content',
  maxWidth: '160px',
  minWidth: '74px',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
})

export const TransactionLog = ({
  startDate: initialStartDate,
  endDate: initialEndDate,
  handleClose,
}: TransactionLogProps): JSX.Element => {
  const { getToken } = useAuth()
  const [startDate, setStartDate] = React.useState<Date | null>(
    initialStartDate ? new Date(initialStartDate) : null
  )
  const [endDate, setEndDate] = React.useState<Date | null>(
    initialEndDate ? new Date(initialEndDate) : null
  )
  const [transactions, setTransactions] = React.useState<Transactions[]>([])
  const [transactionsReady, setTransactionsReady] = React.useState(false)

  const handleStartDateChange = (date: Date | null): void => {
    setStartDate(date)
  }
  const handleEndDateChange = (date: Date | null): void => {
    setEndDate(date)
  }

  React.useEffect(() => {
    const fetchTransactions = async (): Promise<void> => {
      if (startDate && endDate) {
        const start = format(startDate, 'yyyy-MM-dd')
        const end = format(endDate, 'yyyy-MM-dd')
        try {
          const res = await getTransactionLogs(start, end, getToken)
          setTransactions(res)
          setTransactionsReady(true)
        } catch (error) {
          console.error('Failed to fetch transaction logs:', error)
          setTransactionsReady(false)
        }
      } else {
        setTransactionsReady(false)
      }
    }
    void fetchTransactions()
  }, [startDate, endDate, getToken])

  const handleReset = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    event.preventDefault()
    handleClose()
  }

  const headers = [
    { label: 'Visit ID', key: 'visitId' },
    { label: 'Visit Date', key: 'visitDate' },
    {
      label: 'Patient Medical Record Number',
      key: 'patientMedicalRecordNumber',
    },
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Middle Name', key: 'middleName' },
    { label: 'Date of Birth', key: 'dateOfBirth' },
    { label: 'Sticky Note', key: 'stickyNote' },
    {
      label: 'Imported Patient Medical Record Number',
      key: 'importedPatientMedicalRecordNumber',
    },
    { label: 'Is Locked', key: 'isLocked' },
    { label: 'Insurance', key: 'insurance' },
    { label: 'Procedure Codes', key: 'procedureCodes' },
    { label: 'Visit Type', key: 'visitType' },
    { label: 'Visit Category', key: 'visitCategory' },
    { label: 'Patient Type', key: 'patientType' },
    { label: 'Check In Time', key: 'checkInTime' },
    { label: 'Check Out Time', key: 'checkOutTime' },
    { label: 'Clinic Name', key: 'clinicName' },
  ]

  //how it's printed on the TransactionLog itself
  const transactionMap = transactions.map((transaction: Transactions) => ({
    visitId: transaction.visitId ?? '',
    visitDate: formatDateOnly(transaction.visitDate),
    patientMedicalRecordNumber: transaction.patientMedicalRecordNumber ?? '',
    firstName: transaction.firstName ?? '',
    lastName: transaction.lastName ?? '',
    middleName: transaction.middleName ?? '',
    dateOfBirth: formatDateOnly(transaction.dateOfBirth),
    stickyNote: transaction.stickyNote?.replace(/"/g, '""') ?? '',
    importedPatientMedicalRecordNumber:
      transaction.importedPatientMedicalRecordNumber ?? '',
    isLocked: isDate(transaction.lockingDateTime) ? 'True' : 'False',
    insurance:
      transaction.patientInsuranceList
        ?.map((insurance) => insurance.companyName)
        .join(', ') ?? '',
    procedureCodes:
      transaction.visitProcedureCodes
        ?.map((procedure) => procedure.cptCode)
        .join(', ') ?? '',
    visitType: transaction.visitType ?? '',
    visitCategory: transaction.visitCategory ?? '',
    patientType: transaction.patientType ?? '',
    checkInTime: transaction.examStartDateTime ?? '',
    checkOutTime: transaction.dischargeDateTime ?? '',
    clinicName: transaction.clinicName ?? '',
  }))

  return (
    <ModalContainer>
      <ModalTitle>Download Transaction Logs</ModalTitle>
      <ModalRow>
        <TileDatePicker
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
          dataTestId="logStartDate"
        />
        <TileDatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
          dataTestId="logEndDate"
        />
      </ModalRow>
      <ModalButtonRow>
        <ModalButton
          variant="outlined"
          type="reset"
          onClick={(event) => handleReset(event)}
        >
          Cancel
        </ModalButton>
        <StyledButton
          variant="contained"
          color="info"
          startIcon={<DownloadIcon />}
          disabled={
            !startDate || !endDate || startDate > endDate || !transactionsReady
          }
        >
          <CSVLink
            data={transactionMap}
            headers={headers}
            filename={`transaction_logs_${format(
              startDate ?? new Date(),
              'yy-MM-dd'
            )}_to_${format(endDate ?? new Date(), 'yy-MM-dd')}.csv`}
            target="_blank"
            style={{ float: 'right' }}
          >
            Download CSV
          </CSVLink>
        </StyledButton>
      </ModalButtonRow>
    </ModalContainer>
  )
}

interface TransactionLogProps {
  startDate: string;
  endDate: string;
  handleClose: () => void;
}
