import React from 'react'
import { useAuth, useUser } from '@clerk/nextjs'
import { Dialog, styled } from '@mui/material'
import { ModalButton } from '@/components/Config/AddModals/components/ModalButton'
import { EFaxForm } from '@/components/EFax/EFaxForm'
import { type FieldValues, type SubmitHandler, useForm } from 'react-hook-form'
import { type DocumoFax } from '@/types'
import { ModalLayout } from './ModalLayout'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ConfirmationDialog } from './dialogs/ConfirmationDialog'
import { isInputValid } from '@/utility'
import { UrgentIQDocument } from '@/types'
import { useClinicStore, useMutateSendMultipleFaxes } from '@/hook'

const ButtonRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  paddingTop: '20px'
})
const validationSchema = Yup.object().shape({
  to: Yup.string().required('Recipient Name is required'),
  faxPhoneNumber: Yup.string()
    .min(12, 'Fax number must be valid')
    .required('Fax Number is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required')
})

export const SendRecordModal = ({
  handleClose,
  handleSend,
  faxTitle = '',
  availableDocumentList = [],
  isMultipleFax
}: SendRecordProps): JSX.Element => {
  const { getToken } = useAuth()
  const { user } = useUser()
  const { clinicId } = useClinicStore()
  const sendMultipleFaxesMutation = useMutateSendMultipleFaxes(getToken)
  const {
    handleSubmit,
    register,
    control,
    setError,
    formState: { errors },
    setValue
  } = useForm({ resolver: yupResolver(validationSchema) })
  const [dialogOpen, setDialogOpen] = React.useState<boolean>(false)
  const [fax, setFax] = React.useState<DocumoFax>({})
  const [selectedDocumentIds, setSelectedDocumentIds] = React.useState<
    number[]
  >(() => {
    if (availableDocumentList) {
      return availableDocumentList
        .map((doc) => doc.id)
        .filter((id): id is number => id !== undefined)
    }
    return []
  })

  const onSubmit: SubmitHandler<FieldValues> = async (data): Promise<void> => {
    if (!isInputValid(data.faxPhoneNumber)) {
      setError('faxPhoneNumber', { message: 'Valid phone number is required' })
    }
    const newFax: DocumoFax = {
      recipientName: data.to,
      faxNumber: data.faxPhoneNumber,
      subject: data.subject,
      notes: data.message,
      accountUserId: user?.publicMetadata.account_user_id as number,
      clinicId: clinicId,
      documentTypeId: 1,
      patientId: 1,
      visitId: 1,
      storagePath: '/dummy/storage/path',
    }
    setFax(newFax)
    setDialogOpen(true)
  }

  const handleConfirmation = (fax: DocumoFax): void => {
    if (isMultipleFax) {
      sendMultipleFaxesMutation.mutate(
        {
          fax,
          documentIds: selectedDocumentIds
        },
        {
          onSuccess: () => {
            handleClose()
          },
          onError: (error) => {
            console.error('Error sending multiple faxes:', error)
          }
        }
      )
    } else {
      handleSend(fax)
    }
    setDialogOpen(false)
  }

  return (
    <ModalLayout
      title={
        isMultipleFax
          ? `Send Records (${availableDocumentList.length})`
          : 'Send Record'
      }
      handleCancel={handleClose}
      width={'1028px'}
    >
      <Dialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
      >
        <ConfirmationDialog
          fax={fax}
          handleSend={handleConfirmation}
          handleClose={() => setDialogOpen(false)}
        />
      </Dialog>
      <form
        onSubmit={(...args): void => {
          handleSubmit(onSubmit)(...args).catch((error) => {
            throw error
          })
        }}
      >
        <EFaxForm
          register={register}
          control={control}
          errors={errors}
          setError={setError}
          setValue={setValue}
          faxTitle={faxTitle}
          availableDocumentList={availableDocumentList}
          setSelectedDocumentIds={setSelectedDocumentIds}
        />
        <ButtonRow>
          <ModalButton
            variant="contained"
            color="primary"
            type="submit"
          >
            Send
          </ModalButton>
        </ButtonRow>
      </form>
    </ModalLayout>
  )
}

interface SendRecordProps {
  handleClose: () => void
  handleSend: (fax: DocumoFax) => void
  file?: Blob
  faxTitle?: string
  availableDocumentList?: UrgentIQDocument[]
  isMultipleFax?: boolean
}
