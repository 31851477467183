import React from 'react'
import { Checkbox, styled } from '@mui/material'
import {
  TileRow,
  TileColumn,
  TileSubtitle,
  AddTable,
  NotesRow,
  MedicationRow,
  TileDatePicker,
  TileSearchBox,
  HeaderPMH,
  Category,
} from '@/components'
import { type Medication, type PatientMedication } from '@/types'
import {
  dateOnlyToDate,
  isEmpty,
  parseDateToDateOnly,
  getDoseForm,
  toTitleCase,
  generateMedicationId,
} from '@/utility'
import { useGlobalStore, useMedicationStore } from '@/hook'
import { isDate } from 'date-fns'

const CheckBoxRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
})

export const Medications = ({
  patientId,
  medications,
  addMedication,
  deleteMedication,
  notes,
  setNotes,
  medicationName,
  setMedicationName,
  deniesMedication,
  setDeniesMedication,
}: MedicationProps): JSX.Element => {
  const { medications: medicationNames } = useMedicationStore()
  const [inputValue, setInput] = React.useState<string>('')
  const [doseInput, setDoseInput] = React.useState<string>('')
  const [routeInput, setRouteInput] = React.useState<string>('')
  const [route, setRoute] = React.useState<{ id: number; label: string }>({
    id: 0,
    label: '',
  })
  const [dose, setDose] = React.useState<{ id: number; label: string }>({
    id: 0,
    label: '',
  })
  const [doses, setDoses] = React.useState<
    Array<{ id: number; label: string }>
  >([])
  const [routes, setRoutes] = React.useState<
    Array<{ id: number; label: string }>
  >([])
  const [internalDeniesMedication, setInternalDeniesMedication] =
    React.useState<boolean>(deniesMedication)
  const [prescriptionDate, setPrescriptionDate] = React.useState<Date | null>(
    null
  )
  const { setIsEditing } = useGlobalStore()

  type OmittedMedication = Omit<Medication, 'lexiProductNameToSearch'>;

  type MedicationState = Record<string, OmittedMedication[]>;

  const [newMeds, setNewMed] = React.useState<MedicationState>({})

  React.useEffect(() => {
    setInternalDeniesMedication(deniesMedication)
  }, [deniesMedication])

  React.useEffect(() => {
    const validMedications = medicationNames.filter(
      (medication) => medication.lexiProductNameToSearch
    )

    const tempNewMeds = validMedications.reduce(
      (
        acc: Record<string, Array<Omit<Medication, 'lexiProductNameToSearch'>>>,
        cur: Medication
      ) => {
        const { lexiProductNameToSearch, ...rest } = cur

        if (!acc[lexiProductNameToSearch ?? '']) {
          acc[lexiProductNameToSearch ?? ''] = []
        }

        acc[lexiProductNameToSearch ?? ''].push(rest)

        return acc
      },
      {}
    )

    setNewMed(tempNewMeds)
  }, [medicationNames])

  React.useEffect(() => {
    if (
      inputValue ||
      route.id !== 0 ||
      route.label ||
      doseInput ||
      prescriptionDate
    ) {
      setIsEditing(true)
    } else {
      setIsEditing(false)
    }
  }, [inputValue, route, doseInput, prescriptionDate])

  React.useEffect(() => {
    setInput('')
    setDoseInput('')
    setRouteInput('')
    setPrescriptionDate(null)
    setRoute({ id: 0, label: '' })
    setDose({ id: 0, label: '' })
  }, [patientId])

  const handleValueChange = (
    setter: React.Dispatch<React.SetStateAction<{ id: number; label: string }>>,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null,
    additionalLogic?: () => void
  ): void => {
    if (!newValue) {
      setter({ id: 0, label: '' })
      additionalLogic?.()
    } else {
      setter({ id: newValue.id ?? 0, label: newValue.label ?? '' })
    }
  }

  const handleProcedureTypeChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    handleValueChange(setMedicationName, newValue, () => {
      setDoses([])
      setRoutes([])
      setDose({ id: 0, label: '' })
      setRoute({ id: 0, label: '' })
    })

    if (newValue) {
      const filterMeds = newMeds[newValue.label ?? '']

      let filterRoutes = filterMeds.map((med: Medication) => ({
        id: med.lexiDoseFormId ?? 0,
        label: toTitleCase(getDoseForm(med)),
      }))

      filterRoutes = filterRoutes.filter(
        (v, i, a) =>
          a.findIndex((t) => t.id === v.id && t.label === v.label) === i
      )
      setRoutes(filterRoutes)
      if (filterRoutes.length === 1) {
        handleValueChange(setRoute, filterRoutes[0])
      }

      const filterDose = filterMeds
        .filter((med: Medication) => med.lexiDoseFormId === filterRoutes[0].id)
        .map((med: Medication) => ({
          id: med.id ?? 0,
          label: med.strengthString ?? '',
        }))

      setDoses(filterDose)
      if (filterDose.length === 1) {
        handleValueChange(setDose, filterDose[0])
      }
    }
  }

  const handleDoseChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    handleValueChange(setDose, newValue)
  }

  const handleRouteChange = (
    event: any,
    newValue: {
      id: number | undefined;
      label: string | null | undefined;
    } | null
  ): void => {
    handleValueChange(setRoute, newValue, () => {
      setDose({ id: 0, label: '' })
      setDoses([])
    })

    if (newValue) {
      const filterDose = newMeds[medicationName.label ?? '']
        .filter((med: Medication) => med.lexiDoseFormId === newValue.id)
        .map((med: Medication) => ({
          id: med.id ?? 0,
          label: med.strengthString ?? '',
        }))

      setDoses(filterDose)
      if (filterDose.length === 1) {
        handleValueChange(setDose, filterDose[0])
      }
    }
  }

  const handlePrescriptionDate = (event: any): void => {
    setPrescriptionDate(event)
  }

  const handleAddMedication = (): void => {
    if (inputValue === '') {
      return
    }
    const useRefData = dose.id !== 0
    let med: Medication = {}
    if (dose.id !== 0) {
      med = medicationNames.find((medication) => medication.id === dose.id) ?? {
        lexiProductNameRouteDoseForm: '',
        lexiGenProductId: 0,
        lexiDrugSynId: 0,
        lexiSynonymTypeId: 0,
        strengthString: '',
      }
    }

    addMedication({
      patientId,
      medicationName: !useRefData
        ? `${inputValue} ${routeInput} ${doseInput}`
        : `${med.lexiProductNameRouteDoseForm!} [${doseInput}]`,
      ...(useRefData && { lexiGenProductId: med.lexiGenProductId }),
      ...(useRefData && { lexiSynonymTypeId: med.lexiSynonymTypeId }),
      ...(useRefData && { lexiDrugSynId: med.lexiDrugSynId }),

      ...(useRefData && { strength: med.strengthString }),
      ...(useRefData && { doseSpotMedicationId: dose.id }),
      ...(useRefData && { lexiGenDrugId: med.lexiGenDrugID }),

      ...(prescriptionDate === null ||
      prescriptionDate === undefined ||
      !isDate(prescriptionDate)
        ? {}
        : {
            prescriptionDate: parseDateToDateOnly(new Date(prescriptionDate)),
          }),
    }).catch((error) => {
      throw error
    })
    setMedicationName({ id: 0, label: '' })
    setPrescriptionDate(null)
    setInput('')
    setDose({ id: 0, label: '' })
    setRoute({ id: 0, label: '' })
    setDoses([])
    setRoutes([])
    setRouteInput('')
    setDoseInput('')
    setIsEditing(false)
  }

  const handleDeleteMedication = (medication: PatientMedication): void => {
    deleteMedication(medication).catch((error) => {
      throw error
    })
  }

  const handleEditMedication = (medication: PatientMedication): void => {
    if (!isEmpty(medication?.medicationName)) {
      const routeMatches = medication.medicationName?.match(/\((.*?)\)/)
      const nameMatches = medication.medicationName?.split(' (')
      if (
        routeMatches !== null &&
        routeMatches !== undefined &&
        routeMatches.length > 0 &&
        routeMatches[0] !== undefined &&
        routeMatches[0] !== null
      ) {
        setRouteInput(toTitleCase(routeMatches[1]))
      } else {
        setRouteInput(medication.route ?? '')
      }
      if (
        nameMatches !== null &&
        nameMatches !== undefined &&
        nameMatches.length > 0
      ) {
        setInput(nameMatches[0])
      } else {
        setInput(medication.medicationName ?? '')
      }
      setDoseInput(medication.strength ?? '')
    }

    if (medication?.lexiGenProductId && medication?.lexiDrugSynId) {
      const tempMed = medicationNames.find(
        (medicationName) =>
          (medicationName.lexiGenProductId === medication?.lexiGenProductId &&
            medicationName.lexiDrugSynId === medication?.lexiDrugSynId) ||
          `${medicationName.lexiProductNameToSearch ?? ''} [${
            medicationName.strengthString ?? ''
          }]` === medication?.medicationName
      )

      setMedicationName({
        id: generateMedicationId(medication),
        label: medication?.medicationName?.split(' (')[0],
      })

      if (tempMed) {
        const productSearchName = tempMed?.lexiProductNameToSearch ?? ''
        setRoute({
          id: tempMed?.lexiDoseFormId ?? 0,
          label: toTitleCase(getDoseForm(tempMed)),
        })

        setRoutes(
          newMeds[productSearchName].map((medication) => ({
            id: medication.lexiDoseFormId ?? 0,
            label: toTitleCase(getDoseForm(medication)),
          }))
        )
        setDose({
          id: tempMed.id ?? 0,
          label: tempMed?.strengthString ?? '',
        })
      }
    }

    if (medication?.prescriptionDate) {
      setPrescriptionDate(dateOnlyToDate(medication?.prescriptionDate)!)
    }

    handleDeleteMedication(medication)
  }

  const buildConditions = (): JSX.Element => {
    return (
      <AddTable>
        {medications.map((condition) => {
          return (
            <MedicationRow
              key={condition.id}
              medication={condition}
              medicationName={condition.medicationName ?? ''}
              delete={handleDeleteMedication}
              edit={handleEditMedication}
              isMedicationsMissing={
                !condition?.doseSpotMedicationId ||
                !condition?.lexiSynonymTypeId
              }
            />
          )
        })}
      </AddTable>
    )
  }

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setInternalDeniesMedication(event.target.checked)
    setDeniesMedication(event.target.checked)
  }

  const buildMedicationHistories = React.useMemo((): JSX.Element => {
    return (
      <>
        <TileRow key={'medications'} id={'medications'}>
          <TileSearchBox
            label={'Medication Name'}
            dataTestId={'medication'}
            value={medicationName}
            inputValue={inputValue}
            setInputValue={setInput}
            fullsize
            onChange={(event: any, value: any) =>
              handleProcedureTypeChange(event, value)
            }
            options={
              Object.keys(newMeds).map((key, i) => {
                return {
                  id: i,
                  label: key ?? '',
                }
              }) ?? []
            }
          />
        </TileRow>
        <TileRow>
          <TileSearchBox
            label={'Route & Dose Form'}
            dataTestId={'route'}
            value={route}
            inputValue={routeInput}
            setInputValue={setRouteInput}
            options={routes}
            onChange={(event: any, value: any) =>
              handleRouteChange(event, value)
            }
          />
          <TileSearchBox
            label={'Strength'}
            dataTestId={'strength'}
            value={dose}
            inputValue={doseInput}
            setInputValue={setDoseInput}
            options={doses}
            onChange={(event: any, value: any) =>
              handleDoseChange(event, value)
            }
          />
          <TileDatePicker
            label={'Date Prescribed'}
            onChange={handlePrescriptionDate}
            value={prescriptionDate}
            dataTestId={'datePrescribe'}
          />
        </TileRow>
      </>
    )
  }, [
    medicationName,
    inputValue,
    newMeds,
    route,
    routeInput,
    routes,
    dose,
    doseInput,
    doses,
    prescriptionDate,
  ])

  return (
    <Category>
      <TileRow>
        <TileColumn>
          <TileRow>
            <HeaderPMH>Medication</HeaderPMH>
            <CheckBoxRow>
              <TileSubtitle>Patient Denies</TileSubtitle>
              <Checkbox
                checked={internalDeniesMedication}
                onChange={handleCheckBoxChange}
                data-testid={'deniesMedication-checkbox'}
                sx={{ padding: 0, pl: 1 }}
              />
            </CheckBoxRow>
          </TileRow>
          {buildMedicationHistories}
          <NotesRow
            notes={notes}
            setNotes={setNotes}
            title={'Add Medication'}
            handleAdd={handleAddMedication}
            expanded={!isEmpty(notes)}
          />
        </TileColumn>
      </TileRow>
      {
        /* prettier-ignore */
        medications.length > 0
          /* prettier-ignore */
          ? (
            /* prettier-ignore */
            <TileRow>
              {/* prettier-ignore */}
              {buildConditions()}
              {/* prettier-ignore */}
            </TileRow>
            /* prettier-ignore */
            )
          /* prettier-ignore */
          : <></>
        /* prettier-ignore */
      }
    </Category>
  )
}

interface MedicationProps {
  patientId: number;
  medications: PatientMedication[];
  addMedication: (medication: PatientMedication) => Promise<void>;
  deleteMedication: (medication: PatientMedication) => Promise<void>;
  notes: string;
  setNotes: (notes: string) => void;
  medicationName: { id: number; label: string };
  setMedicationName: any;
  deniesMedication: boolean;
  setDeniesMedication: any;
}
