import React from 'react'
import { useAuth } from '@clerk/nextjs'
import {
  TextField,
  Autocomplete,
  Button,
  Box,
  Paper,
  styled,
  Modal,
  CircularProgress
} from '@mui/material'
import { type ExternalProvider } from '@/types'
import { useQuerySearchExternalProvider, useDebounce, useQueryGetStates } from '@/hook'
import { AddExternalProvider } from '@/components/Config'
import PCPDropdownItem from './PCPDropdownItem'

const BottomDiv = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0px 16px 16px 16px',
  marginTop: 0,
  zIndex: 1000
})

const StyledButton = styled(Button)({
  fontSize: '14px'
})

export const PCPDropdown: React.FC<PCPDropdownProps> = ({
  dataTestId,
  onChange,
  value,
  inputValue = null,
  setInputValue,
  width = '240px',
  placeholder,
  hasAddButton
}) => {
  const { getToken } = useAuth()

  const [internalInputValue, setInternalInputValue] = React.useState<string>('')
  const [openAddExternalProvider, setOpenAddExternalProvider] =
    React.useState(false)

  const [isDropdownOpen, setDropdownOpen] = React.useState(false)

  const debouncedInputValue = useDebounce(internalInputValue, 500)

  const { data: filteredData, isFetching } = useQuerySearchExternalProvider(
    debouncedInputValue.trim() !== '' ? debouncedInputValue : undefined,
    getToken
  )

  useQueryGetStates(getToken)

  const containerRef = React.useRef<HTMLDivElement>(null)

  const handleOpenAddExternalProvider = (): void =>
    setOpenAddExternalProvider(true)

  const handleCloseAddExternalProvider = (): void =>
    setOpenAddExternalProvider(false)

  React.useEffect(() => {
    if (value !== null) {
      if (setInputValue !== undefined) {
        const label = `${value?.firstName ?? ''} ${value?.lastName ?? ''}`
        setInputValue(label)
      } else {
        const label = `${value?.firstName ?? ''} ${value?.lastName ?? ''}`
        setInternalInputValue(label)
      }
    }
  }, [value])

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleInputChange = (
    event: React.ChangeEvent<{}>,
    newInputValue: string
  ): void => {
    setInternalInputValue(newInputValue)
    if (setInputValue) {
      setInputValue(newInputValue)
    }
    if (newInputValue.trim() !== '') {
      setDropdownOpen(true)
    }
  }

  const handleAddNewContact = (event: React.MouseEvent): void => {
    handleOpenAddExternalProvider()
  }

  const handleClickOutside = (event: MouseEvent): void => {
    if (
      containerRef.current &&
      !containerRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false)
    }
  }

  const handleOnAddedProvider = (provider: ExternalProvider): void => {
    onChange(null, provider)
  }

  return (
    <>
      <Autocomplete
        sx={{
          width,
          height: '40px',
          '& .MuiOutlinedInput-root': {
            height: '40px',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }
        }}
        open={
          isDropdownOpen || (isFetching && debouncedInputValue.trim() !== '')
        }
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
        id="tags-standard"
        onChange={(event: any, newValue: ExternalProvider | null) => {
          setDropdownOpen(false)
          onChange(event, newValue)
        }}
        value={value}
        options={(filteredData || []).map((provider) => ({
          ...provider,
          label: `${provider.firstName ?? ''} ${provider.lastName ?? ''}`
        }))}
        filterOptions={(x) => x}
        getOptionLabel={(option) =>
          `${option.firstName ?? ''} ${option.lastName ?? ''}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              'data-testid': dataTestId
            }}
            placeholder={placeholder}
            onChange={(event) => {
              onChange(event, null)
              setInputValue(event.target.value)
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isFetching &&
                  debouncedInputValue.length > 0 &&
                  filteredData &&
                  filteredData?.length > 0 ? (
                    <CircularProgress
                      color="inherit"
                      size={20}
                    />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
        PaperComponent={({ children, ...other }) => (
          <Paper
            {...other}
            style={{ width: width === '100%' ? '100%' : '350px' }}
            ref={containerRef}
          >
            {children}
            {hasAddButton && (
              <BottomDiv>
                <StyledButton
                  variant="outlined"
                  onClick={handleAddNewContact}
                >
                  Create new Contact
                </StyledButton>
              </BottomDiv>
            )}
          </Paper>
        )}
        renderOption={(props, option) => (
          <PCPDropdownItem
            {...props}
            providerData={option}
          />
        )}
        isOptionEqualToValue={(option, value) => {
          const optionId = option.id
          const valueId = value.id
          return optionId === valueId
        }}
        inputValue={inputValue === null ? internalInputValue : inputValue}
        onInputChange={handleInputChange}
        handleHomeEndKeys
        loading={isFetching}
        noOptionsText={
          isFetching
            ? 'Loading...'
            : debouncedInputValue?.length > 0
            ? 'No results found, enter manually or add provider to contacts'
            : null
        }
        data-testid={'pcp-dropdown'}
      />
      <Modal
        open={openAddExternalProvider}
        onClose={handleCloseAddExternalProvider}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <AddExternalProvider
          handleClose={handleCloseAddExternalProvider}
          onAddProvider={handleOnAddedProvider}
        />
      </Modal>
    </>
  )
}

interface PCPDropdownProps {
  data: ExternalProvider[]
  onChange: any
  value: ExternalProvider | null
  dataTestId: string
  width?: string
  inputValue?: string | null
  setInputValue?: any | undefined
  placeholder?: string
  hasAddButton?: boolean
}
