import React from 'react'
import { type Patient, type Medication } from '@/types'
import { useAuth } from '@clerk/nextjs'
import {
  useMutateAddPatientMedication,
  useQueryGetPatientMedication,
  useMutateDeletePatientMedication,
  useQueryGetMedication,
} from '@/hook'
import {
  formatDateOnly,
  getDoseForm,
  toTitleCase,
  generateMedicationId,
  parseMedicationId,
  parseDateToDateOnly,
  isEmpty,
} from '@/utility'
import { PMHTile } from '../PatientMedicalHistory/PatientHistoryTile'
import { isDate } from 'date-fns'

export const PatientMedications = ({
  patient,
}: {
  patient: Patient;
}): JSX.Element => {
  const { getToken } = useAuth()

  const [selectedMedication, setSelectedMedication] = React.useState<{
    code: string;
    id: number;
    label: string;
  } | null>()

  const [newMeds, setNewMeds] = React.useState<Record<string, Medication[]>>()

  const [route, setRoute] = React.useState<{ id: number; label: string }>()
  const [routes, setRoutes] = React.useState<
    Array<{ id: number; label: string }>
  >([])

  const [strength, setStrength] = React.useState<{
    id: number;
    label: string;
  }>()
  const [strengths, setStrengths] = React.useState<
    Array<{ id: number; label: string }>
  >([])

  const {
    data: medications,
    isLoading: isMedicationTableLoading,
    refetch,
  } = useQueryGetPatientMedication(patient?.id!, getToken)

  const { data: medicationNames } = useQueryGetMedication(getToken)

  const addNewPatientMedication = useMutateAddPatientMedication(getToken)
  const deletePatientMedication = useMutateDeletePatientMedication(getToken)

  React.useEffect(() => {
    if (selectedMedication) {
      const initialMeds: Record<
        string,
        Array<Omit<Medication, 'lexiProductNameToSearch'>>
      > = {}

      const tempMeds =
        medicationNames
          ?.filter((medication: any) => medication.lexiProductNameToSearch)
          .reduce(
            (
              acc: Record<
                string,
                Array<Omit<Medication, 'lexiProductNameToSearch'>>
              >,
              cur: Medication
            ) => {
              const { lexiProductNameToSearch, ...rest } = cur
              if (!acc[lexiProductNameToSearch ?? '']) {
                acc[lexiProductNameToSearch ?? ''] = []
              }

              acc[lexiProductNameToSearch ?? ''].push(rest)

              return acc
            },
            initialMeds
          ) ?? {}
      setNewMeds(tempMeds)
    }
  }, [selectedMedication, medicationNames])

  React.useEffect(() => {
    if (selectedMedication && newMeds) {
      const filterMeds = newMeds[selectedMedication?.label ?? ''] ?? []
      let filterRoutes = filterMeds.map((med) => ({
        id: med.lexiDoseFormId ?? 0,
        label: toTitleCase(getDoseForm(med)),
      }))

      filterRoutes = filterRoutes.filter(
        (route, index, self) =>
          index ===
          self.findIndex((t) => t.id === route.id && t.label === route.label)
      )
      setRoutes(filterRoutes)

      if (filterRoutes.length === 1) {
        setRoute(filterRoutes[0])
      }
    }
  }, [newMeds])

  React.useEffect(() => {
    if (route && newMeds) {
      const filterMeds = newMeds[selectedMedication?.label ?? ''] ?? []
      const filterStrengths = filterMeds.map((med) => ({
        id: med.id ?? 0,
        label: med.strengthString ?? '',
      }))

      setStrengths(filterStrengths)
      if (filterStrengths.length === 1) {
        setStrength(filterStrengths[0])
      }
    }
  }, [route, newMeds])

  const handleMedicationChange = (medicationId: any): void => {
    setSelectedMedication(medicationId)
  }

  const handleRouteChange = (routeId: any): void => {
    const selectedRoute = routes.find((route) => route.id === routeId.id)
    setRoute(selectedRoute)
  }

  if (!medications) {
    return <div>No medications data available.</div>
  }

  if (isMedicationTableLoading) {
    return <div>Loading medications...</div>
  }

  const patientMedicationsWithName = medications?.map((medication) => {
    const routeMatches = medication.medicationName?.match(/\((.*?)\)/)
    const nameMatches = medication.medicationName?.split(' (')
    let routeName = ''
    let medicationName = ''
    if (
      routeMatches !== null &&
      routeMatches !== undefined &&
      routeMatches.length > 0 &&
      routeMatches[0] !== undefined &&
      routeMatches[0] !== null
    ) {
      routeName = toTitleCase(routeMatches[1])
    } else {
      routeName = medication.route ?? ''
    }
    if (
      nameMatches !== null &&
      nameMatches !== undefined &&
      nameMatches.length > 0
    ) {
      medicationName = nameMatches[0]
    } else {
      medicationName = medication.medicationName ?? ''
    }
    return {
      ...medication,
      medicationName: medicationName ?? 'Unknown Medication Name',
      route: routeName ?? 'Unknown Route',
      strength: medication.strength ?? 'Unknown Strength',
      date: formatDateOnly(medication.prescriptionDate),
    }
  })

  const columns = [
    { accessor: 'medicationName', Header: 'Medication Name' },
    { accessor: 'route', Header: 'Route & Dose Form' },
    { accessor: 'strength', Header: 'Strength' },
    { accessor: 'date', Header: 'Date' },
  ]

  const dialogFields = [
    {
      id: 'medicationName',
      label: 'Medication Name',
      type: 'select',
      onChange: handleMedicationChange,
      options: medicationNames
        ? Object.keys(
            medicationNames
              .filter((medication) => medication.lexiProductNameToSearch)
              .reduce<
                Record<
                  string,
                  Array<Omit<Medication, 'lexiProductNameToSearch'>>
                >
              >((acc, cur: Medication) => {
                const { lexiProductNameToSearch, ...rest } = cur
                const key = lexiProductNameToSearch ?? ''
                acc[key] = acc[key] ?? []
                acc[key].push(rest)
                return acc
              }, {})
          ).map((key, i) => {
            return {
              id: generateMedicationId(medicationNames[i]),
              label: key,
            }
          })
        : [],
    },
    {
      id: 'route',
      label: 'Route & Dose Form',
      type: 'select',
      onChange: handleRouteChange,
      options: routes?.map((route) => ({
        id: route.id,
        label: route.label,
      })),
      defaultValue: route,
    },
    {
      id: 'strength',
      label: 'Strength',
      type: 'select',
      options: strengths?.map((strength) => ({
        id: strength.id,
        label: strength.label,
      })),
      defaultValue: strength,
    },
    {
      id: 'date',
      label: 'Date',
      type: 'date',
    },
  ]

  const handleAdd = async (values: any): Promise<void> => {
    const { lexiGenProductId, lexiSynonymTypeId, lexiDrugSynId } =
      parseMedicationId(selectedMedication?.id!)

    const newPatientMedication = {
      patientId: patient?.id,
      medicationName: `${selectedMedication?.label!} (${route?.label!}) - ${
        values.undefined_label as string
      }`.trim(),
      prescriptionDate:
        values.undefined !== undefined && isDate(values.undefined)
          ? parseDateToDateOnly(values.undefined)
          : undefined,
      lexiGenProductId,
      lexiSynonymTypeId,
      lexiDrugSynId,
      strength: !isEmpty(values.undefined_label)
        ? values.undefined_label
        : undefined,
      doseSpotMedicationId: route?.id,
    }

    await addNewPatientMedication.mutateAsync({
      patientMedication: newPatientMedication,
    })
    await refetch()
  }

  const handleDelete = async (medicineToDelete: any): Promise<void> => {
    if (!medicineToDelete) {
      console.error('Could not find medicine to delete')
      return
    }
    await deletePatientMedication.mutateAsync({
      patientMedicationId: medicineToDelete.id,
    })
    await refetch()
  }

  return (
    <PMHTile
      title="Medications"
      data={patientMedicationsWithName}
      columns={columns}
      onAdd={handleAdd}
      onEdit={undefined}
      onDelete={handleDelete}
      dialogFields={dialogFields}
      fullSize={true}
    />
  )
}
